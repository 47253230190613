import React, { useEffect } from 'react';

function VideoPlayer({ title, year, shikimoriID, season, episode, videoUrl }) {
  useEffect(() => {
    const kodikPlayerDiv = document.getElementById('kodik-player');
    if (kodikPlayerDiv) {
      const kodikAddPlayers = {
        title: title || "НАЗВАНИЕ_ФИЛЬМА",
        year: year || "ГОД",
        shikimori_id: shikimoriID || "ID_SHIKIMORI",
        types: "anime,anime-serial",
        season: season || "НОМЕР_СЕЗОНА",
        episode: episode || "НОМЕР_ЭПИЗОДА"
      };

      const script = document.createElement("script");
      script.src = "//kodik-add.com/add-players.min.js";
      script.async = true;
      script.onload = () => {
        if (window.Kodik) {
          try {
            console.log('Initializing Kodik player with params:', kodikAddPlayers);
            window.Kodik.init(kodikAddPlayers);
          } catch (error) {
            console.error('Ошибка при инициализации Kodik плеера:', error);
          }
        }
      };
      script.onerror = () => {
        console.log('Failed to load Kodik script');
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      console.error('Kodik player div not found');
    }
  }, [title, year, shikimoriID, season, episode]);

  return (
    <div>
      {videoUrl ? (
        <iframe src={videoUrl} width="100%" height="650" frameBorder="0" allowFullScreen></iframe>
      ) : (
        <p>Видео не найдено</p>
      )}
      <div id="kodik-player"></div>
    </div>
  );
}

export default VideoPlayer;
