import React, { useState, useEffect } from "react";
import { request, gql } from 'graphql-request';
import { Link } from "react-router-dom";
import '../style.css';
import notPicture from '../img/not_picture.png';

const endpoint = 'https://shikimori.one/api/graphql';

// Добавляем URL для проверки видео в базе Kodik
const kodikEndpoint = `https://kodikapi.com/search?token=6c29a4ee6eb1f841b920f0ff8814ef24&with_material_data=true&shikimori_id=`;

function SearchModal({ onClose }) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Проверяем наличие видео в базе Kodik
  const checkKodikVideo = async (animeId) => {
    try {
      const response = await fetch(kodikEndpoint + animeId);
      const data = await response.json();
      return data.results && data.results.length > 0; // Если есть результаты, возвращаем true
    } catch (error) {
      console.error('Ошибка при проверке Kodik API:', error);
      return false;
    }
  };

  useEffect(() => {
    if (query.length > 2) {
      const fetchSearchResults = async () => {
        setLoading(true);
        const searchQuery = gql`
          {
            animes(search: "${query}", limit: 20) {
              id
              name
              russian
              score
              poster {
                previewUrl
              }
            }
          }
        `;

        try {
          const data = await request(endpoint, searchQuery);
          const filteredResults = [];

          // Для каждого аниме проверяем наличие видео в Kodik API
          for (const anime of data.animes) {
            const hasVideo = await checkKodikVideo(anime.id);
            if (hasVideo) {
              filteredResults.push(anime); // Добавляем только те, у которых есть видео
            }
          }

          setResults(filteredResults.slice(0, 5));
        } catch (error) {
          setError('Ошибка при выполнении поиска: ' + error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchSearchResults();
    } else {
      setResults([]);
    }
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="search-modal">
      <div className="search-modal-content">
        <button className="close-button" onClick={onClose}>Закрыть</button>
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Введите название аниме"
        />
        {loading && <p>Загрузка...</p>}
        {error && <p>{error}</p>}

        <div className="search-results">
          <div className="search-items">
          {results.map((anime) => (
            <Link key={anime.id} to={`/anime/${anime.id}`} className="search-result-link">
              <div className="search-result-item">
                <img 
                  src={anime.poster?.previewUrl || notPicture}
                  alt={anime.russian} 
                />
                <div>
                  <h3>{anime.russian}</h3>
                  <p>Рейтинг: {anime.score}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
}

export default SearchModal;
