  import React, { useEffect, useState } from 'react';
  import { useParams, Link } from 'react-router-dom';
  import { request, gql } from 'graphql-request';
  import VideoPlayer from '../VideoPlayer';
  import { Lightbox } from 'yet-another-react-lightbox';
  import 'yet-another-react-lightbox/styles.css';
  import '../style.css';
  

  const shikimoriEndpoint = 'https://shikimori.one/api/graphql';
  const kodikApiKey = '6c29a4ee6eb1f841b920f0ff8814ef24';

  const AnimePage = () => {
    const { id } = useParams();
    const [anime, setAnime] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [relatedWithVideo, setRelatedWithVideo] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const cleanDescription = (description) => {
      if (!description) return '';
      return description.replace(/\[.*?\]/g, '').replace(/[\p{Script=Han}]/gu, '');
    };

    const fetchKodikDescription = async (shikimoriId) => {
      try {
        const response = await fetch(`https://kodikapi.com/search?token=${kodikApiKey}&shikimori_id=${encodeURIComponent(shikimoriId)}&with_episodes=true&with_material_data=true`);
        const data = await response.json();
        // Find the first video with description data
        const video = data.results.find(result => result.material_data && result.material_data.description);
        return video ? video.material_data.description || '' : '';
      } catch (error) {
        console.error('Error fetching Kodik description:', error);
        return '';
      }
    };
    

    const fetchKodikVideo = async (shikimoriId) => {
      try {
        const response = await fetch(`https://kodikapi.com/search?token=${kodikApiKey}&shikimori_id=${encodeURIComponent(shikimoriId)}&with_episodes=true`);
        const data = await response.json();
        const video = data.results.find(result => result.shikimori_id.toString() === shikimoriId.toString());
        return video ? `https:${video.link}` : null;
      } catch (error) {
        return null;
      }
    };

    const openModal = (index) => {
      if (anime && anime.screenshots && anime.screenshots.length > 0) {
        setPhotoIndex(index);
        setIsOpen(true);
      }
    };

    const closeModal = () => {
      setIsOpen(false);
      setPhotoIndex(0);
    };

    useEffect(() => {
      const fetchAnimeData = async () => {
        const query = gql`
          {
            animes(limit: 1, ids: "${id}") {
              id
              russian
              description
              name
              score
              status
              episodes
              duration
              poster {
                mainUrl
                originalUrl
              }
              releasedOn {
                year
              }
              related {
                id
                anime {
                  id
                  russian
                  kind
                  poster {
                    mainUrl
                  }
                }
              }
              genres {
                id
                russian
              }
              studios {
                name
              }
              screenshots {
                originalUrl
              }
            }
          }
        `;

        try {
          const data = await request(shikimoriEndpoint, query);
          const fetchedAnime = data.animes[0];

          if (fetchedAnime) {
            let description = cleanDescription(fetchedAnime.description);

            // Если описание пустое, получить его из Kodik
            if (!description) {
              description = await fetchKodikDescription(id);
            }

            setAnime({ ...fetchedAnime, description });
            const kodikVideoUrl = await fetchKodikVideo(id);
            setVideoUrl(kodikVideoUrl);

            const relatedAnimeWithVideos = await Promise.all(
              fetchedAnime.related.map(async (relation) => {
                if (relation.anime) {
                  const relatedVideoUrl = await fetchKodikVideo(relation.anime.id);
                  return relatedVideoUrl ? relation.anime : null;
                }
                return null;
              })
            );

            setRelatedWithVideo(relatedAnimeWithVideos.filter(Boolean));
          } else {
            setError('Аниме не найдено.');
          }

          setLoading(false);
        } catch (error) {
          setError('Ошибка при запросе аниме: ' + error.message);
          setLoading(false);
        }
      };

      fetchAnimeData();
    }, [id]);

    if (loading) return <p>Загрузка...</p>;
    if (error) return <p>{error}</p>;
    if (!anime) return <p>Аниме не найдено.</p>;

    return (
      <div className='wrap-page-anime'>
        <div className='wrap-items-page'>

                {anime.poster?.originalUrl && (
                  <img className='img-page-bg' src={anime.poster.originalUrl} alt={anime.russian} />
                )}
                <div className="gradient-overlay-bottom"></div>

          <div className='container'>
            <div className='wrap-content-anime-page'>
              <div className='wrap-img-page'>
                {anime.poster?.mainUrl && (
                  <img className='img-page' src={anime.poster.mainUrl} alt={anime.russian} />
                )}
                <div className='slide-buttons'>
                  <div className='watch-now'>
                    Plan to watch
                    <div className='wrap-img-btn'>
                      <svg width='29' height='25' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M10.2502 1.60001C9.86895 1.67188 9.59395 2.00938 9.6002 2.40001V37.6C9.59708 37.8875 9.7502 38.1531 9.99708 38.2969C10.244 38.4438 10.5502 38.4438 10.8002 38.3L20.0002 32.925L29.2002 38.3C29.4502 38.4438 29.7565 38.4438 30.0033 38.2969C30.2502 38.1531 30.4033 37.8875 30.4002 37.6V2.40001C30.4002 1.95938 30.0408 1.60001 29.6002 1.60001H10.4002C10.3752 1.60001 10.3502 1.60001 10.3252 1.60001C10.3002 1.60001 10.2752 1.60001 10.2502 1.60001ZM11.2002 3.20001H28.8002V36.2L20.4002 31.3C20.1533 31.1563 19.8471 31.1563 19.6002 31.3L11.2002 36.2V3.20001Z' fill='black' />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className='wrap-heading-and-description'>
                <h2>{anime.russian}</h2>
                {anime.genres?.length > 0 && (
                  <div className='genres'>
                    <div className='item-genre'>
                      {anime.genres.map((genre) => (
                        <p key={genre.id}>{genre.russian}</p>
                      ))}
                    </div>
                  </div>
                )}
                <p>{cleanDescription(anime.description)}</p>
              </div>
              <div className='wrap-info-anime'>
                <p>Рейтинг: {anime.score}</p>
                <p>Статус: {anime.status}</p>
                <p>Оригинальное название: <br /> {anime.name}</p>
                <p>Релиз: {anime.releasedOn?.year}</p>
                <p>Эпизодов: {anime.episodes}</p>
                {anime.studios?.length > 0 && (
                  <p>Студия: {anime.studios.map(studio => studio.name).join(', ')}</p>
                )}
                <p>Продолжительность: {anime.duration}</p>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='wrap-screenshots'>
                   {anime.screenshots?.length > 0 && (
              <div className='screenshots'>
                {anime.screenshots.map((screenshot, index) => (
                  <div key={index} className='wrap-screenshot'>
                    <img  className="screenshot-img" src={screenshot.originalUrl} alt={`Screenshot ${index}`} onClick={() => openModal(index)} />
                  </div>
                ))}
                {isOpen && (
                  <Lightbox
                    slides={anime.screenshots.map((screenshot) => ({ src: screenshot.originalUrl }))}
                    open={isOpen}
                    index={photoIndex}
                    close={() => setIsOpen(false)}
                    onSlideChange={(index) => setPhotoIndex(index)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
          <div className='container'>
        {videoUrl && (
          <div className='wrap-video'>
            <VideoPlayer videoUrl={videoUrl} />
          </div>
        )}
          </div>
        {relatedWithVideo.length > 0 && (
          <div className='wrap-related-anime'>
            <div className='container'>
            <h2>Свзанные аниме</h2>
            <div className='related-anime-item'>
              <div className='related-conteiner'>
              {relatedWithVideo.map((relatedAnime) => (
                <div key={relatedAnime.id} className='wrap-related-items'>
                  <Link to={`/anime/${relatedAnime.id}`}>
                    <img src={relatedAnime.poster?.mainUrl} alt={relatedAnime.russian} />
                    <p>{relatedAnime.russian}</p>
                  </Link>
                </div>
              ))}
              </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  export default AnimePage;
