import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimeProvider } from './AnimeContext';
import Home from './components/Home';
import AnimeDetails from './components/AnimeDetails';
import AnimePage from './components/AnimePage';
import './style.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import DMCA from './components/DMCA';
import Contact from './components/Contact';
import FilterPage from './components/FilterPage';
import UpcomingAnimePage from './components/UpcomingAnimePage';
import TopAnime from './components/TopAnime';
import TermsOfService from './components/TermsOfService';



function App() {
  return (
    <AnimeProvider>
      <Router>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/details/:id" element={<AnimeDetails />} />
          <Route path="/anime/:id" element={<AnimePage />} />
          <Route path="/dmca" element={<DMCA />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/genres" element={<FilterPage />} />
          <Route path="/upcoming" element={<UpcomingAnimePage />} />
          <Route path="/top-100" element={<TopAnime />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />

        </Routes>
        <Footer/>
      </Router>
    </AnimeProvider>
  );
}


export default App;
