import { useState } from "react";
import emailjs from "emailjs-com";

function Contact() {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        emailjs.sendForm('service_v48fpwq', 'template_nbay2kf', event.target, 'zmOiN5642eHwZ-_Ma')
            .then((result) => {
                console.log(result.text);
                setSuccess(true);
                event.target.reset();
            }, (error) => {
                console.log(error.text);
                setError('Failed to send DMCA notice. Please try again later.');
            });
    };

    return (
        <>
            <section className='wrap-dmca'>
                <div className="container">
                    <div className="wrap-items-dmca">
                        <h2>Пожалуйста, отправьте свой запрос, используя форму ниже, и мы свяжемся с вами в ближайшее время.</h2>

                        <p>Пожалуйста, отправляйте ваши уведомления с помощью следующей формы:</p>
                        <form className="wrap-form" onSubmit={handleSubmit}>
                            <label>
                                <span className="wrap-text-label">Ваше имя  <span className="required">*</span>:</span>
                                <input type="text" name="name" required />
                            </label>
                            <label>
                                <span className="wrap-text-label">Ваш E-Mail <span className="required">*</span>:</span>
                                <input type="email" name="email" required />
                            </label>
                            <label>
                                <span className="wrap-text-label">Тема сообщения <span className="required">*</span>:</span>
                                <input type="text" name="subject" required />
                            </label>
                            <label>
                                <span className="wrap-text-label">Ваше письмо <span className="required">*</span>:</span>
                                <textarea name="message" rows="4" required />
                            </label>
                            <button className="watch-now center" type="submit">Отправить уведомление</button>
                        </form>

                        {success && 
                        <div className="answer-email">
                        <p>Ваше уведомление было успешно отправлено. Спасибо!</p>
                        </div>
                        }

                        {error &&
                            <div className="answer-email"> 
                            <p>{error}</p>
                        </div>}
                        </div>
                
                </div>
            </section>
        </>
    );
}

export default Contact;
