import React, { useState } from "react";
import { Link } from "react-router-dom";
import searchIcon from "../img/search.svg";
import user from "../img/user.svg";
import logo from "../img/logo_animexcross 1.svg";
import SearchModal from "./SearchModal";
import "../style.css";

function NavBar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleSearchModal = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav>
        <div className="bg-nav">
          <div className="container nav-container">
            <div className="wrap-logo">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <div className='wrap-link'>
                <Link to="/genres">Жанры</Link>
                <Link to="/top-100">Топ-100 аниме</Link>
                <Link to="/upcoming">Ожидаемые Аниме</Link>
                <Link to="/contact">Контакт</Link>
              </div>
              <div className="wrap-user-icon">
                <div className="search" onClick={toggleSearchModal}>
                  <img src={searchIcon} alt="search" />
                </div>
                <div className="user">
                  <img src={user} alt="user" />
                </div>
                <div className="burger-menu" onClick={toggleMenu}>
                <div className={`burger-line ${isMenuOpen ? 'open' : ''}`}></div>
                <div className={`burger-line ${isMenuOpen ? 'open' : ''}`}></div>
                <div className={`burger-line ${isMenuOpen ? 'open' : ''}`}></div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {isSearchOpen && <SearchModal onClose={toggleSearchModal} />}

      {isMenuOpen && (
        <div className="mobile-menu">
          <Link to="/genres" onClick={toggleMenu}>Жанры</Link>
          <Link to="/top-100" onClick={toggleMenu}>Топ-100 аниме</Link>
          <Link to="/upcoming" onClick={toggleMenu}>Ожидаемые Аниме</Link>
          <Link to="/contact">Контакт</Link>
        </div>
      )}
    </>
  );
}

export default NavBar;
