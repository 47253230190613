import { useState } from "react";
import emailjs from "emailjs-com";

function DMCA() {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        emailjs.sendForm('service_v48fpwq', 'template_nbay2kf', event.target, 'zmOiN5642eHwZ-_Ma')
            .then((result) => {
                console.log(result.text);
                setSuccess(true);
                event.target.reset();
            }, (error) => {
                console.log(error.text);
                setError('Failed to send DMCA notice. Please try again later.');
            });
    };

    return (
        <>
            <section className='wrap-dmca'>
                <div className="container">
                    <div className="wrap-items-dmca">
                        <h2>Уведомление о нарушении авторских прав (DMCA)</h2>
                        <p>
                            Мы уважаем права интеллектуальной собственности и обязуемся соблюдать законы о защите авторских прав, такие как Закон об авторском праве в цифровую эпоху (DMCA). Если вы являетесь владельцем авторских прав или его уполномоченным представителем и полагаете, что какой-либо контент, размещенный на нашем сайте, нарушает ваши авторские права, вы можете отправить нам уведомление о нарушении в соответствии с DMCA.
                            Для подачи уведомления о нарушении авторских прав, пожалуйста, предоставьте следующую информацию:
                        </p>

                        <ul>
                            <li>
                                Ваши контактные данные:
                                <ol>
                                    <li>Полное имя.</li>
                                    <li>Адрес электронной почты.</li>
                                    <li>Прямую ссылку(ссылки) на материал, который вы считаете спорным.</li>
                                    <li>Заверенные сканированные копии документов, подтверждающих ваше эксклюзивное право на материал.</li>
                                    <li>В случае, если вы представляете интересы другой компании – копии документов на посреднические услуги</li>
                                </ol>
                            </li>
                            <li>
                                Описание материала, защищенного авторским правом, который, по вашему мнению, был нарушен. Пожалуйста, предоставьте достаточно информации, чтобы мы могли идентифицировать материал, включая URL-адреса, если применимо.
                            </li>
                            <li>
                                Описание материала, который, как вы считаете, нарушает ваши права, а также его местонахождение на нашем сайте (укажите конкретные страницы или URL-адреса).
                            </li>
                            <li>
                                Заявление, в котором вы указываете, что добросовестно полагаете, что использование материала не было разрешено владельцем авторских прав, его агентом или законом.
                            </li>
                            <li>
                                Заявление под присягой, в котором вы подтверждаете, что вся информация в вашем уведомлении является точной и что вы являетесь владельцем авторских прав или уполномочены действовать от его имени.
                            </li>
                            <li>
                                Электронная или физическая подпись владельца авторских прав или его представителя.
                            </li>
                        </ul>

                        <p>Пожалуйста, отправляйте ваши уведомления с помощью следующей формы:</p>
                        <form className="wrap-form" onSubmit={handleSubmit}>
                            <label>
                                <span className="wrap-text-label">Ваше имя  <span className="required">*</span>:</span>
                                <input type="text" name="name" required />
                            </label>
                            <label>
                                <span className="wrap-text-label">Ваш E-Mail <span className="required">*</span>:</span>
                                <input type="email" name="email" required />
                            </label>
                            <label>
                                <span className="wrap-text-label">Тема сообщения <span className="required">*</span>:</span>
                                <input type="text" name="subject" required />
                            </label>
                            <label>
                                <span className="wrap-text-label">Ваше письмо <span className="required">*</span>:</span>
                                <textarea name="message" rows="4" required />
                            </label>
                            <button className="watch-now center" type="submit">Отправить уведомление</button>
                        </form>
                        
                        {success && 
                        <div className="answer-email">
                            <p>Ваше уведомление было успешно отправлено. Спасибо!</p>
                            </div>
                        }
                        {error && <div className="answer-email">
                            <p>{error}</p>
                            </div>
                            }
                    
                    
                    </div>
                </div>
            </section>
        </>
    );
}

export default DMCA;
