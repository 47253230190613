import React from 'react';
import MostPopular from './MostPopular';
import MostFavorite from './MostFavorite';
import GenresList from './Movie';
import NavBar from './NavBar';
import Slider from './Slider';
import '../style.css';
import Footer from './Footer';
import MostTrend from './MostTrend';

const Home = () => {
  return (
    <div className="homepage">
      <div className="content">
        <Slider /> 
        <MostTrend />
        <MostPopular />
        <MostFavorite />
        <GenresList />
      </div>
    </div>
  );
};

export default Home;
