import React, { createContext, useState, useContext, useEffect } from 'react';

// Создание контекста
const AnimeContext = createContext();

// Провайдер контекста
export const AnimeProvider = ({ children }) => {
  const [anime, setAnime] = useState(null);
  const [lastWatchedAnime, setLastWatchedAnime] = useState(null);

  useEffect(() => {
    // Извлечение данных о последнем просмотренном аниме из localStorage
    const storedAnime = localStorage.getItem('lastWatchedAnime');
    if (storedAnime) {
      setLastWatchedAnime(JSON.parse(storedAnime));
    }
  }, []);

  // Обновление данных о последнем просмотренном аниме и сохранение их в localStorage
  const updateLastWatchedAnime = (anime) => {
    setLastWatchedAnime(anime);
    localStorage.setItem('lastWatchedAnime', JSON.stringify(anime));
  };

  return (
    <AnimeContext.Provider value={{ anime, setAnime, lastWatchedAnime, updateLastWatchedAnime }}>
      {children}
    </AnimeContext.Provider>
  );
};

// Хук для использования контекста
export const useAnime = () => useContext(AnimeContext);
