import React, { useEffect, useState } from 'react';
import { request, gql } from 'graphql-request';

const endpoint = 'https://shikimori.one/api/graphql';

const UpcomingAnimePage = () => {
  const [upcomingAnime, setUpcomingAnime] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUpcomingAnime = async (page = 1) => {
    setIsLoading(true);

    const query = gql`
      {
        animes(limit: 20, page: ${page}, order: aired_on) {
          id
          name
          russian
          japanese
          score
          status
          episodes
          season 
          description
          airedOn { year month day }
          poster { mainUrl }
          genres { name russian }
          studios { name }
        }
      }
    `;

    try {
      const data = await request(endpoint, query);
      if (data.animes.length === 0) {
        setHasMore(false);
      } else {
        setUpcomingAnime(prevAnime => [...prevAnime, ...data.animes]);
      }
    } catch (error) {
      console.error('Ошибка при запросе аниме:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingAnime(currentPage);
  }, [currentPage]);

  const handleScroll = () => {
    const scrollTop = window.scrollY || window.pageYOffset;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const bodyHeight = document.body.scrollHeight || document.documentElement.scrollHeight;

    if (windowHeight + scrollTop >= bodyHeight / 2 && hasMore && !isLoading) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, isLoading]);

  return (
    <div>
      <section className='s2 upcoming-s'>
        <div className='bg'>
          <div className='container'>
            <div className='wrap-upcoming-anime'>
              <h1>Предстоящие аниме</h1>
              <div className='wrap-items-upcoming'>
                {upcomingAnime.length > 0 ? (
                  upcomingAnime.map((anime) => (
                    <div className='wrap-anime' key={anime.id}>
                      <img className='img-anime' src={anime.poster.mainUrl} alt={anime.russian || anime.name} />
                      <div className='anime-info info-upcoming'>
                        <h3>{anime.russian || anime.name}</h3>
                        <p>Дата выхода: {anime.airedOn ? `${anime.airedOn.year}` : 'Неизвестно'}</p>
                        <p>Оригинальное название: {anime.japanese}</p>
                        <p>Студии: {anime.studios.map((studio) => studio.name).join(', ')}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Загружаем предстоящие аниме...</p>
                )}
                {isLoading && <div>Загрузка...</div>}
                {!hasMore && <div>Нет больше аниме для загрузки</div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpcomingAnimePage;
